import React, { useContext } from 'react';
import {
  DefaultTheme,
  NavigationContainer,
  NavigationContainerRef,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

// context
import { AuthContext } from '../screens/auth/auth.utils';

// screens
import AuthScreen from '../screens/auth/auth.screen';
import ConnectScreen from '../screens/connect/connect.screen';
import { WebNavigation } from '../components/WebNavigation/WebNavigation';
import type { StackParamList } from './navigation.types';
import RegisterScreen from '../screens/register/register.screen';
import HomeScreen from '../screens/home/home.screen.web';
import { ProfileScreen } from '../screens/profile/Profile.screen';
import ActiveStreamScreen from '../screens/activeStream/ActiveStream.screen';
import ResetPasswordScreen from '../screens/resetPassword/resetPassword.screen';
import LogRouteToGoogleAnalytics from './logRouteToGoogleAnalytics';
import ThanksForSubscribingScreen from '../screens/thanksForSubscrbing';
import ActivateScreen from '../screens/activate/activate.screen';
import { navigationRef } from './navigation.ref';
import { StatisticsScreen } from '#/screens/statistics/Statistics.screen';
import { TariffScreen } from '#/screens/tariff/Tariff.screen';
import { ReferAFriendScreen } from '#/screens/referAFriend/ReferAFriend.screen';
import { OverlaysScreen } from '#/screens/overlays/Overlays.screen';
import { ChatsScreen } from '#/screens/chats/Chats.screen';

const Stack = createStackNavigator<StackParamList>();

const config = {
  screens: {
    Connect: 'connect',
    SignIn: 'login',
    Register: 'register/:referralCode?',
    RegisterCoupon: 'register-coupon/:referralCode?',
    Home: 'home',
    Profile: 'profile',
    Tariff: 'tariff',
    Statistics: 'statistics',
    ActiveStream: 'stream',
    ResetPassword: 'reset-password',
    ThanksForSubscribing: 'thanks-for-subscribing',
    ReferAFriend: 'refer-a-friend',
    Activate: 'activate',
    Overlays: 'overlays',
  },
};

const linking = {
  prefixes: [],
  config,
};

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'transparent',
  },
};

export default function Navigation() {
  const { isAuth } = useContext(AuthContext);

  return (
    <NavigationContainer linking={linking} theme={MyTheme} ref={navigationRef}>
      {isAuth && <WebNavigation />}
      <LogRouteToGoogleAnalytics />
      <Stack.Navigator
        initialRouteName={isAuth ? 'Connect' : 'SignIn'}
        screenOptions={{ headerShown: false, detachPreviousScreen: true }}
      >
        {isAuth ? (
          <>
            <Stack.Screen name="Connect" component={ConnectScreen} />
            <Stack.Screen name="Home" component={HomeScreen} />
            <Stack.Screen name="Profile" component={ProfileScreen} />
            <Stack.Screen name="Tariff" component={TariffScreen} />
            <Stack.Screen name="Statistics" component={StatisticsScreen} />
            <Stack.Screen name="Overlays" component={OverlaysScreen} />
            <Stack.Screen name="Chats" component={ChatsScreen} />
            <Stack.Screen
              name="ReferAFriend"
              component={ReferAFriendScreen}
              options={{ title: 'Refer a friend' }}
            />
            <Stack.Screen
              name="ActiveStream"
              component={ActiveStreamScreen}
              options={{ title: 'Live stream' }}
            />
            <Stack.Screen
              name="ThanksForSubscribing"
              component={ThanksForSubscribingScreen}
              options={{ title: 'Thanks for subscribing' }}
            />
            <Stack.Screen
              name="Activate"
              component={ActivateScreen}
              options={{ title: 'Activate your account' }}
            />
          </>
        ) : (
          <>
            <Stack.Screen name="SignIn" component={AuthScreen} options={{ title: 'Sign in' }} />
            <Stack.Screen
              name="RegisterCoupon"
              options={{ title: 'Register' }}
              component={RegisterScreen}
            />
            <Stack.Screen name="Register" component={RegisterScreen} />
            <Stack.Screen
              name="ResetPassword"
              component={ResetPasswordScreen}
              options={{ title: 'Reset password' }}
            />
            <Stack.Screen
              name="Activate"
              component={ActivateScreen}
              options={{ title: 'Activate your account' }}
            />
            <Stack.Screen
              name="ThanksForSubscribing"
              component={ThanksForSubscribingScreen}
              options={{ title: 'Thanks for subscribing' }}
            />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}
