import mixins from '#/app/styles';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  wrapper: { flexGrow: 1 },
  buttonRow: {
    flexDirection: 'row',
    gap: 15,
    marginBottom: 15,
    flexWrap: 'wrap',
  },
  chatRow: {
    flexDirection: 'row',
    gap: 15,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  chatNameRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: mixins.color.bottomSheet,
    borderColor: mixins.color.blueShadow,
    borderStyle: 'solid',
    borderBottomWidth: 1,

    padding: 5,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  chatName: {
    color: mixins.color.whiteText,
  },
  chat: {
    flexGrow: 1,
    flexBasis: 0,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: mixins.color.blueShadow,
    backgroundColor: mixins.color.darker,
  },
  alert: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
    marginTop: 50,
  },
});
