import Button from '#/components/Button/Button';
import { useManageBoxControls } from '#/screens/stream/manageBox/utils';
import {
  getInitialPlatformChat,
  useChatMessagesStore,
} from '#/store/chatMessages/chatMessagesStore';
import { ChatState, Target } from '#/socket/document.type';
import { SocketContext } from '#/socket/socket.provider';
import { useContext, useEffect, useState } from 'react';
import { TextInput, View, Text, FlatList, Pressable } from 'react-native';
import { styles } from './ChatBox.styles';
import CloseIcon from '#/assets/icons/close.svg';
import { ChatBoxStatusDisplay } from '#/components/ChatBoxStatusDisplay/ChatBoxStatusDisplay';
import mixins from '#/app/styles';
import {
  ChatsData,
  ChatsAuthor,
  ChatsMessage,
  ChatsMessageType,
} from '#/store/chatMessages/chatMessagesStoreTypes';
import { useChatInitialization } from '#/components/ChatBox/hooks/useChatInitialization';
import { useKeyboardShowHide } from '#/components/ChatBox/hooks/useKeyboardShowHide';

const getChatData = (
  chatsData: ChatsData,
  authorId: string | undefined,
  targetId?: string,
): ChatsData[string] => {
  const defaultState = getInitialPlatformChat();

  if (targetId) {
    if (authorId && chatsData[targetId]) {
      return {
        ...chatsData[targetId],
        messages: chatsData[targetId].messages.filter(
          m => m.AuthorId === authorId || m.ToAuthorId === authorId,
        ),
      };
    }

    return chatsData[targetId] || defaultState;
  }

  Object.entries(chatsData).forEach(([target, chat]) => {
    defaultState.messages.push(...chat.messages.slice(-100).map(m => ({ ...m, target })));
    defaultState.unreadMessages += chat.unreadMessages;
    defaultState.unseenTips += chat.unseenTips;

    Object.values(chat.authors).forEach(author => {
      defaultState.authors[author.Id] = { ...author, target };
    });
  });

  defaultState.messages.sort((a, z) => a.Time.getTime() - z.Time.getTime());

  return defaultState;
};

type ChatBoxProps = {
  targetId?: string;
  placeholderText?: string;
};

export const ChatBox: React.FC<ChatBoxProps> = ({ targetId, placeholderText }) => {
  const { serverData, sendPatch } = useContext(SocketContext);
  const manageBoxControls = useManageBoxControls();
  const [message, setMessage] = useState('');
  const {
    chatsData,
    replyTo,
    setReplyTo,
    clearAllUnreadMessages,
    clearUnreadMessages,
    sendChatMessage,
  } = useChatMessagesStore();

  const [chatId, chatObject] =
    Object.entries(serverData.Platforms.Chats).find(c => c[1].TargetId === targetId) || [];
  const chatData = getChatData(chatsData, replyTo, targetId);

  useChatInitialization({ sendPatch, targetId, chatId });
  useKeyboardShowHide();

  useEffect(() => {
    if (!chatData.unreadMessages && !chatData.unseenTips) {
      return;
    }

    if (targetId) {
      clearUnreadMessages(targetId);
    } else {
      clearAllUnreadMessages();
    }
  }, [chatData.unreadMessages, targetId]);

  if (targetId && (!chatId || chatObject?.State !== ChatState.Connected)) {
    return <ChatBoxStatusDisplay chat={chatObject} chatId={chatId} />;
  }

  const handleSendMessage = () => {
    if (!chatId) {
      return;
    }

    sendChatMessage(chatId, message, replyTo);
    setMessage('');
  };

  const renderAuthor = (author: ChatsAuthor, message: ChatsMessage, target: Target) => {
    let name = '';

    if (author.target) {
      name = author.Self ? target.Name : `${author.Name} | ${target.Name}`;
    } else {
      name = author.Self ? '' : author.Name;
    }

    if (message.Private) {
      if (author.Self && message.ToAuthorId) {
        name += ` private @ ${chatData.authors[message.ToAuthorId].Name}`;
      } else {
        name += ` @ private`;
      }
    }

    return name;
  };

  const messages = chatData.messages.slice(-100).reverse();

  if (chatData.messages.length === 0 && placeholderText) {
    return (
      <View style={styles.wrapper}>
        <Text style={styles.placeholderText}>{placeholderText}</Text>
      </View>
    );
  }

  return (
    <View style={styles.wrapper}>
      <FlatList
        style={styles.scrollViewStyles}
        contentContainerStyle={styles.scrollViewContainer}
        inverted
        keyboardDismissMode="none"
        data={messages}
        renderItem={({ item, index }: { item: ChatsMessage; index: number }) => {
          const author = chatData.authors[item.AuthorId];
          const previousMessage = messages[index + 1];
          const isPrevMessageFromTheSameAuthor =
            previousMessage && previousMessage.AuthorId === item.AuthorId;
          const fullTarget = serverData.Targets[targetId || author.target || ''];

          const handleMessagePress = () => {
            if (item.Internal) {
              return;
            }

            if (author.Self && item.ToAuthorId) {
              setReplyTo(item.ToAuthorId);
            }
            if (!author.Self) {
              setReplyTo(item.AuthorId);
            }

            if (!author.target) {
              return;
            }

            const [id] =
              Object.entries(serverData.Channels).find(
                ([_id, channel]) => channel.TargetId === author.target,
              ) || [];

            if (!id) {
              return;
            }

            manageBoxControls.editChannel(id, 'CHATS');
          };

          const shouldDisplayAuthor = () => {
            if (author.Self) {
              if (!isPrevMessageFromTheSameAuthor && !item.target && !item.ToAuthorId) {
                return false;
              }

              if (!isPrevMessageFromTheSameAuthor && item.target) {
                return true;
              }

              if (previousMessage?.ToAuthorId !== item.ToAuthorId && item.Private) {
                return true;
              }

              return false;
            }

            if (isPrevMessageFromTheSameAuthor && item.Private === previousMessage.Private) {
              return false;
            }

            return true;
          };

          return (
            <View
              key={`${item.Time}-${item.Msg}`}
              style={[styles.messageWrapper, author.Self && styles.myMessageWrapper]}
            >
              <Pressable
                onPress={handleMessagePress}
                style={[
                  styles.message,
                  author.Self && styles.myMessage,
                  item.Private && styles.privateMessageWrapper,
                  item.Type === ChatsMessageType.Tip &&
                    (item.Private ? styles.privateTipMessage : styles.tipMessage),
                ]}
              >
                {shouldDisplayAuthor() && (
                  <Text style={styles.author}>{renderAuthor(author, item, fullTarget)}</Text>
                )}
                <Text style={styles.messageText}>{item.Msg}</Text>
              </Pressable>
            </View>
          );
        }}
      />
      {chatId && (
        <View style={styles.inputRow}>
          {replyTo && (
            <Pressable style={styles.replyToWrapper} onPress={() => setReplyTo(undefined)}>
              <Text style={styles.replyToTitle}>private to</Text>
              <Text style={styles.replyToName}>{chatData.authors[replyTo].Name}</Text>
              <CloseIcon style={styles.replyToClose} fill={mixins.color.white} />
            </Pressable>
          )}
          <TextInput
            placeholder={replyTo ? 'Private message' : 'Public message'}
            value={message}
            onChangeText={setMessage}
            placeholderTextColor="#ccc"
            style={[styles.input, replyTo ? styles.replyInput : undefined]}
            onSubmitEditing={handleSendMessage}
          />
          <Button style={styles.button} onPress={handleSendMessage}>
            Send
          </Button>
        </View>
      )}
    </View>
  );
};
